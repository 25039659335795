import { ROUTES } from 'app/utils/constants/paths';
import React from 'react';

import CreditsPage from '../pages/Credits';
import LoginPage from '../pages/LoginPage/LoginPage';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TabAuthEnd from '../pages/Teams/TabAuthEnd';
import TabAuthStart from '../pages/Teams/TabAuthStart';
import TermsOfUse from '../pages/TermsOfUse';
import SamlCallback from '../pages/saml/SamlCallback';
import SamlError from '../pages/saml/SamlError';

const DeepLinkView = React.lazy(() => import('_core/lite/views/DeepLinkView'));
const LogoutPage = React.lazy(() => import('../modules/containers/Logout'));
const AuthPage = React.lazy(() => import('../pages/auth'));
const TokenCallback = React.lazy(() => import('../pages/auth/TokenCallback'));
const CoursePage = React.lazy(() => import('app/pages/CoursePage/CoursePage'));
const ErrorView = React.lazy(() => import('app/modules/views/ErrorView/ErrorView'));
const HomePage = React.lazy(() => import('app/pages/HomePage/HomePage'));
const LessonPage = React.lazy(() => import('app/pages/LessonPage/LessonPage'));
const AddLicense = React.lazy(() => import('app/modules/views/AddLicenseView/AddLicenseView'));
const ToolsProjectContainer = React.lazy(() => import('_core/lite/views/ToolsProjectView/ToolsProjectContainer'));
const AssessmentAnswerStudent = React.lazy(() => import('../pages/AssessmentAnswerStudent'));
const AssessmentResult = React.lazy(() => import('../pages/AssessmentResult'));
const AssessmentResultIndividual = React.lazy(() => import('../pages/AssessmentResultIndividual'));
const AssessmentResult2 = React.lazy(() => import('../pages/AssessmentResultToMock'));
const CalendarPage = React.lazy(() => import('../pages/CalendarPage/CalendarPage'));
const LemonTest = React.lazy(() => import('../pages/LemonTest'));
const NewContentView = React.lazy(() => import('../pages/NewContent'));
const NewActivityPage = React.lazy(() => import('../pages/NewActivityPage/NewActivityPage.js'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/ProfilePage'));
const ProjectionMint = React.lazy(() => import('../pages/ProjectionMint'));
const Projection = React.lazy(() => import('../pages/Projection'));
const ProjectionTest = React.lazy(() => import('../pages/ProjectionTest'));
const ProjectionQuestion = React.lazy(() => import('../pages/ProjectionQuestion'));
const ProjectionVideoLesson = React.lazy(() => import('../pages/ProjectionVideoLesson'));
const ProjectionPower = React.lazy(() => import('../pages/ProjectionPower'));
const RemotePage = React.lazy(() => import('../pages/RemotePage'));
const ViewerV1 = React.lazy(() => import('../pages/viewers/ViewersContainer'));
const Viewer = React.lazy(() => import('app/containers/ViewerContainer/ViewerContainer'));
const ScormResult = React.lazy(() => import('../pages/ScormResultPage/ScormResultPage'));
const XapiResult = React.lazy(() => import('../pages/XapiResultsPage/XapiResultsPage'));
const ScormIndividualResult = React.lazy(() => import('../pages/ScormIndividualResultPage/ScormIndividualResultPage'));
const XapiIndividualResult = React.lazy(() => import('../pages/XapiIndividualResultPage/XapiIndividualResultPage'));
const IndividualActivityPage = React.lazy(() => import('../pages/IndividualActivityPage/IndividualActivityPage'));
const RecoverPasswordPage = React.lazy(() => import('../pages/RecoverPasswordPage/RecoverPasswordPage'));
const TestComponentView = React.lazy(() => import('app/modules/views/TestComponentView/TestComponentView'));
const MSTeamsConfiguration = React.lazy(() => import('../pages/MSTeamsConfiguration.js'));
const GroupResourcesPage = React.lazy(() => import('../pages/GroupResourcesPage/GroupResourcesPage'));
const UiPage = React.lazy(() => import('../pages/UiPage/UiPage'));
const KanbanPage = React.lazy(() => import('app/pages/KanbanPage/KanbanPage'));
const TasksPage = React.lazy(() => import('app/pages/TasksPage'));
const AssessmentPage = React.lazy(() => import('app/pages/AssessmentPage'));
const ResourcePage = React.lazy(() => import('app/pages/ResourcePage'));

export const externalRoutes = [
  { path: ROUTES.REMOTE, component: RemotePage },
  { path: ROUTES.DEEPLINK, component: DeepLinkView },
  { path: ROUTES.DEEPLINK_TOKEN, component: DeepLinkView },
  { path: ROUTES.SAML_ERROR, component: SamlError },
  { path: ROUTES.TEAMS_CONFIG, component: MSTeamsConfiguration },
  { path: ROUTES.TEAMS_TAB_AUTH_START, component: TabAuthStart },
  { path: ROUTES.TEAMS_TAB_AUTH_END, component: TabAuthEnd },
  { path: ROUTES.PRIVACY, component: PrivacyPolicy },
  { path: ROUTES.TERMS, component: TermsOfUse },
  { path: ROUTES.CREDITS, component: CreditsPage },
  { path: ROUTES.LOGOUT, component: LogoutPage },
];

export const publicRoutes = [
  { path: ROUTES.AUTH_LOGIN_TOKEN, component: TokenCallback },
  { path: ROUTES.SAML_CALLBACK, component: SamlCallback },
  { path: ROUTES.OIDC_CALLBACK, component: SamlCallback },
  { path: ROUTES.AUTH_LOGIN, component: LoginPage },
  { path: ROUTES.AUTH_SIGNUP, component: AuthPage },
  { path: ROUTES.RECOVER_PASSWORD, component: RecoverPasswordPage },
];

export const localhostRoutes = [
  { path: ROUTES.UI, component: UiPage },
  { path: ROUTES.TEST_COMPONENT, component: TestComponentView },
];

//Importante: El orden de las rutas es importante, ya que se renderizan en el orden en el que se encuentran
export const privateRoutes = [
  { path: ROUTES.HOME, component: HomePage },
  { path: ROUTES.QUESTION, component: LemonTest },
  { path: ROUTES.NEW_QUESTION, component: LemonTest },
  { path: ROUTES.RESULTS_ASSESSMENT, component: AssessmentResult },
  { path: ROUTES.RESOURCE, component: ResourcePage },
  { path: ROUTES.RESULTS_SCORM, component: ScormResult },
  { path: ROUTES.RESULTS_XAPI, component: XapiResult },
  { path: ROUTES.ASSESSMENT, component: AssessmentPage },
  { path: ROUTES.NEW_CONTENT, component: NewContentView },
  { path: ROUTES.CONTENT, component: NewContentView },
  { path: ROUTES.KANBAN, component: KanbanPage },
  { path: ROUTES.PROJECTION_MINT, component: ProjectionMint },
  { path: ROUTES.PROJECTION_UNIT, component: Projection },
  { path: ROUTES.PROJECTION_POWER, component: ProjectionPower },
  { path: ROUTES.PROJECTION_MINT, component: ProjectionMint },
  { path: ROUTES.ADD_ITEM, component: NewActivityPage },
  { path: ROUTES.EDIT_ITEM, component: NewActivityPage },
  { path: ROUTES.PROJECTION, component: Projection },
  { path: ROUTES.PROJECTION_TEST, component: ProjectionTest },
  { path: ROUTES.PROJECTION_QUESTION, component: ProjectionQuestion },
  { path: ROUTES.PROJECTION_VIDEOLESSON, component: ProjectionVideoLesson },
  { path: ROUTES.LESSON_SESSION, component: LessonPage },
  { path: ROUTES.LESSON, component: LessonPage },
  { path: ROUTES.COURSE_GRADES, component: CoursePage, props: { tabId: 'grades' } },
  { path: ROUTES.COURSE_PROGRAM, component: CoursePage, props: { tabId: 'program' } },
  { path: ROUTES.GROUP_RESOURCES_PAGE, component: GroupResourcesPage },
  { path: ROUTES.COURSE_RESOURCES, component: CoursePage, props: { tabId: 'resources' } },
  { path: ROUTES.COURSE, component: CoursePage, props: { tabId: 'stream' } },
  //VISOR V1
  { path: ROUTES.VIEWER_FULL_V1, component: ViewerV1, props: { showHeader: false } },
  { path: ROUTES.VIEWER_V1, component: ViewerV1, props: { showHeader: true } },
  { path: ROUTES.VIEWER_FULL_NUTRIENT, component: ViewerV1, props: { showHeader: false } },
  { path: ROUTES.VIEWER_FULL, component: ViewerV1, props: { showHeader: false } },
  { path: ROUTES.VIEWER, component: ViewerV1, props: { showHeader: true } },
  { path: ROUTES.VIEWER_GROUP_RESOURCES, component: ViewerV1, props: { showHeader: true } },
  //VISOR V2
  { path: ROUTES.VIEWER_FULL_NUTRIENT_V2, component: Viewer, props: { showHeader: false, pdfLibrary: 'nutrient' } },
  { path: ROUTES.VIEWER_FULL_V2, component: Viewer, props: { showHeader: false } },
  { path: ROUTES.CALENDAR, component: CalendarPage },
  { path: ROUTES.CALENDAR_COURSE, component: CalendarPage },
  { path: ROUTES.PROFILE, component: ProfilePage },
  { path: ROUTES.TASKS, component: TasksPage },
  { path: ROUTES.LEMON_TEST, component: LemonTest },
  { path: ROUTES.ANSWER_TEST, component: AssessmentAnswerStudent },
  { path: ROUTES.RESULTS_ASSESSMENT_2, component: AssessmentResult2 },
  { path: ROUTES.RESULTS_ASSESSMENT_INVIDIDUAL, component: AssessmentResultIndividual },
  { path: ROUTES.RESULTS_SCORM_INDIVIDUAL, component: ScormIndividualResult },
  { path: ROUTES.RESULTS_XAPI_INDIVIDUAL, component: XapiIndividualResult },
  { path: ROUTES.SINGLE_ACTIVITY_RECORD, component: IndividualActivityPage, props: { record: true } },
  { path: ROUTES.SINGLE_ACTIVITY, component: IndividualActivityPage },
  { path: ROUTES.TOOLS, component: ToolsProjectContainer },
  { path: ROUTES.REMOTE, component: RemotePage },
  { path: ROUTES.ERROR, component: ErrorView },
  { path: ROUTES.LICENSE, component: AddLicense },
];
