import * as coreEntities from '_core/store/index';
import { isEditorial, isSchoolAdmin, isStudent, isTeacher, isUserFromGoogle, isUserFromMicrosoft } from '_core/utils/user';
import { useSelector } from 'react-redux';

export function useUser() {
  const user = useSelector((state) => coreEntities.auth.selectors.getUser(state));
  const authToken = useSelector((state) => coreEntities.auth.selectors.getAuthToken(state));
  const oraculoToken = useSelector((state) => coreEntities.auth.selectors.getOraculoToken(state));
  const schoolGuid = user?.schools && user?.schools?.length ? user.schools[0].guid : null;
  const oauthAccounts = useSelector((state) => coreEntities.auth.selectors.getOauthAccounts(state));

  return {
    user,
    authToken,
    schoolGuid,
    oauthAccounts,
    isStudent: isStudent(user),
    isTeacher: isTeacher(user),
    isEditorial: isEditorial(user),
    isSchoolAdmin: isSchoolAdmin(user),
    isUserFromMicrosoft: isUserFromMicrosoft(oauthAccounts),
    isUserFromGoogle: isUserFromGoogle(oauthAccounts),
    isUserFromOraculo: user?.oraculo_id,
    isUserDemo: user?.is_demo === 1,
    oraculoToken: oraculoToken,
  };
}
